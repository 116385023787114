import React, { useState, useEffect } from 'react';
import Input from "../../components/util/Input";
import Button from "../../components/util/Button";
import { ResumeDataResponse } from '../../constants/types';
import apiClient from '../../service/instance';
import FullLoader from '../../components/util/FullLoader';

interface CustomProps {
    submit: (tab?: number) => void;
}

interface Experience {
    company: string;
    position: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    isCurrentCompany: boolean;
    description: string;
}

const Experience = (props: CustomProps) => {
    const [saveBtnLoader, setSaveBtnLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [experiences, setExperiences] = useState<Experience[]>([{
        company: '',
        position: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        isCurrentCompany: false,
        description: ''
    }]);

    useEffect(() => {
        getResumeData();
    }, []);

    const saveStep = async () => {
        setLoading(true);
        setSaveBtnLoader(true);
        try {
            setSaveBtnLoader(false);
            // Filter out empty experiences
            const nonEmptyExperiences = experiences.filter(exp =>
                exp.company || exp.position || exp.startMonth || exp.startYear || exp.endMonth || exp.endYear || exp.description
            );
            // Generate JSON data and save to local storage
            const experienceData = JSON.stringify(nonEmptyExperiences);
            // localStorage.setItem('experiance', experienceData);

            // Make API request
            const response = await apiClient.post('save_resume', {
                type: 'experiance',
                data: JSON.stringify(nonEmptyExperiences)
            });

            if (!response.status) {
                throw new Error('Failed to save data');
            }

            props.submit(2);

        } catch (error) {
        } finally {
            setLoading(false);
        }   
    }

    const getResumeData = async () => {
        setLoading(true);
        try {
            const response: ResumeDataResponse = await apiClient.post('get_resume_data', {
                type: 'experiance'
            });
            if (response.data.status) {
                const savedData = JSON.parse(response.data.resume_data.cgmu_data);
                setExperiences(savedData);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const handleExperienceChange = (index: number, field: keyof Experience, value: string | boolean) => {
        const newExperiences = [...experiences];
        newExperiences[index] = {
            ...newExperiences[index],
            [field]: value
        };
        setExperiences(newExperiences);
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            years.push(i.toString());
        }
        return years;
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const addExperience = () => {
        setExperiences([...experiences, { company: '', position: '', startMonth: '', startYear: '', endMonth: '', endYear: '', isCurrentCompany: false, description: '' }]);
    };

    const removeExperience = (index: number) => {
        const newExperiences = [...experiences];
        newExperiences.splice(index, 1);
        setExperiences(newExperiences);
    };

    const previousStep = () => {
        props.submit(0);
    }

    return (
        <>
            <FullLoader show={loading} />
            <div className='block mt-9 max-w-[90%] m-auto'>
                <form>
                    <div className="flex flex-col gap-4">
                        {experiences.map((exp, index) => (
                            <div key={index} className="flex flex-col gap-4 p-4 border border-gray-200 rounded-md relative bg-[#edeff3]">
                                <div className="flex flex-col">
                                    <label htmlFor={`company-${index}`} className="block mb-2 text-sm text-gray-500">Company</label>
                                    <Input
                                        onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
                                        placeholder="Enter company name"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={exp.company}
                                        name={`company-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`position-${index}`} className="block mb-2 text-sm text-gray-500">Position</label>
                                    <Input
                                        onChange={(e) => handleExperienceChange(index, 'position', e.target.value)}
                                        placeholder="Enter your position"
                                        customClass=""
                                        type="text"
                                        label=""
                                        error={''}
                                        value={exp.position}
                                        name={`position-${index}`}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`startMonth-${index}`} className="block mb-2 text-sm text-gray-500">Start Date</label>
                                        <div className="flex gap-2">
                                            <select
                                                onChange={(e) => handleExperienceChange(index, 'startMonth', e.target.value)}
                                                value={exp.startMonth}
                                                name={`startMonth-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Month</option>
                                                {months.map((month, i) => (
                                                    <option key={i} value={month}>{month}</option>
                                                ))}
                                            </select>
                                            <select
                                                onChange={(e) => handleExperienceChange(index, 'startYear', e.target.value)}
                                                value={exp.startYear}
                                                name={`startYear-${index}`}
                                                className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                            >
                                                <option value="">Year</option>
                                                {generateYearOptions().map((year) => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <label htmlFor={`endMonth-${index}`} className="block mb-2 text-sm text-gray-500">End Date</label>
                                        <div className="flex items-center gap-2 mb-2">
                                            <input
                                                type="checkbox"
                                                id={`currentCompany-${index}`}
                                                checked={exp.isCurrentCompany}
                                                onChange={(e) => handleExperienceChange(index, 'isCurrentCompany', e.target.checked)}
                                                className="mr-2"
                                            />
                                            <label htmlFor={`currentCompany-${index}`} className="text-sm text-gray-500">Current Company</label>
                                        </div>
                                        {!exp.isCurrentCompany && (
                                            <div className="flex gap-2 mt-2">
                                                <select
                                                    onChange={(e) => handleExperienceChange(index, 'endMonth', e.target.value)}
                                                    value={exp.endMonth}
                                                    name={`endMonth-${index}`}
                                                    className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                                >
                                                    <option value="">Month</option>
                                                    {months.map((month, i) => (
                                                        <option key={i} value={month}>{month}</option>
                                                    ))}
                                                </select>
                                                <select
                                                    onChange={(e) => handleExperienceChange(index, 'endYear', e.target.value)}
                                                    value={exp.endYear}
                                                    name={`endYear-${index}`}
                                                    className="numo-outer-btn-200 hover:nm-flat-gray-200-lg py-2 bg-white rounded-full px-4 placeholder:text-xs appearance-none w-[50%]"
                                                >
                                                    <option value="">Year</option>
                                                    {generateYearOptions().map((year) => (
                                                        <option key={year} value={year}>{year}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor={`description-${index}`} className="block mb-2 text-sm text-gray-500">Description</label>
                                    <Input
                                        onChange={(e) => handleExperienceChange(index, 'description', e.target.value)}
                                        placeholder="Describe your responsibilities and achievements"
                                        customClass=""
                                        type="textarea"
                                        label=""
                                        error={''}
                                        value={exp.description}
                                        name={`description-${index}`}
                                        parentClass={``}
                                    />
                                </div>
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => removeExperience(index)}
                                        className='absolute top-2 right-2 opacity-50'
                                    >
                                        <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" /></svg>
                                    </button>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addExperience}
                            className="text-blue-500 hover:text-blue-700 mt-2 flex flex-row items-center gap-2 justify-center font-normal text-sm"
                        >
                            Add Experience
                            <svg width={20} height={20} viewBox="0 0 512 512"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 176v160M336 256H176" /></svg>
                        </button>
                        <div className="flex justify-center pt-9 items-center gap-4">
                            <Button type="button" name={'Previous'} class="w-32" loader={saveBtnLoader} onClick={() => previousStep()} />
                            <Button type="button" name={'Next'} class="w-32" loader={saveBtnLoader} onClick={() => saveStep()} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Experience;
